import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from 'axios';
import HelpIcon from '@material-ui/icons/Help';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Autocomplete from '@material-ui/lab/Autocomplete';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const theme = createMuiTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        width: '1em',
        height: '1em'
      }
    },
    MuiDialog: {
      paperWidthSm: {
        // boxShadow: 'none',
        width: '40%',
        maxHeight: '80%',
        '@media(max-width: 768px)': {
          width: '80%',
          maxHeight: '70%',
        }
      }
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'center'
      }
    },
    PrivateSwitchBase: {
      root: {
        padding: '5px'
      }
    },
    MuiInputLabel: {
      root: {
        color: 'white',
        fontSize: '13px',
        fontWeight: 500
      },
    },
    MuiFormLabel: {
      root: {
        '&.Mui-focused': {
          color: 'white',
          fontWeight: 500,
          fontSize: '13px',
        },
        '&.Mui-error': {
          color: 'unset'
        }
      },
      asterisk: {
        '&.Mui-error': {
          color: 'unset'
        }
      }
    },
    MuiInput: {
      root: {
        fontSize: '0.85rem',
        color: 'white',
        fontFamily: "Titillium Web, sans-serif"
      },
      input: {
        textTransform: 'uppercase',
        color: 'white'
      },
      underline: {
        '&:hover:not(.Mui-disabled):before': {
          borderBottomColor: 'white',
        },
        "&::before": {
          borderBottom: '2px solid white'
        },
        "&::after": {
          borderBottom: '2px solid white'
        },
        '&.Mui-error::after': {
          borderBottomColor: 'white'
        },
        '&.Mui-error': {
          // borderBottomColor: '#f44336',
          borderBottomColor: 'white',
        }
      }
    }
  }
})

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px 2em 0px 0em',
    margin: 'calc(1rem / 2) 0px',
    color: '#fff'
  },
  radioButton: {
    fontWeight: '600',
    fontStyle: 'normal',
    fontSize: '13px',
    marginLeft: '2px'
  },
  radioButtonBlack: {
    color: 'black',
    fontWeight: '600',
    fontStyle: 'normal',
    fontSize: '13px',
    marginLeft: '2px'
  },
  helpIcon: {
    marginTop: 7,
    color: '#fff',
    width: '0.9em',
    height: '0.9em',
    '&:hover': {
      opacity: '0.8'
    }
  },
  dialogHeader: {
    fontSize: '1.8rem',
    fontFamily: 'Titillium Web,sans-serif',
    borderStyle: 'solid solid solid solid',
    borderWidth: '0px 0px 6px 0px',
    borderColor: props => `#00000000 #00000000 ${props.secondary_color}`
  },
  dialogContent: {
    fontFamily: 'Titillium Web,sans-serif',
    overflowY: 'auto',
    color: '#000'
  },
  customBtn: {
    fontFamily: "Titillium Web, sans-serif",
    borderRadius: '0.35em',
    transitionTimingFunction: 'cubic-bezier(0.400,0.000,0.200,1.000)',
    transitionDuration: '0.3s',
    boxShadow: 'none',
    border: '0px',
    fontSize: '1.11em',
    fontStyle: 'normal',
    fontWeight: '600 !important',
    lineHeight: '1.3',
    width: '334px',
    height: '51px',
    marginBottom: 5,
    '@media(max-width: 425px)': {
      width: 115,
    },
  },
  whiteBg: {
    backgroundColor: props => props.primary_color,
    color: 'white',
    marginTop: 35,
    '&:hover': {
      backgroundColor: '#292929',
      color: 'white !important',
    }
  }
}));

export default function VacinationGroup({ userErrors = {}, changeRequiredFields, healthWorkerGroups = [], setHealthWorkerGroupId, healthWorkerGroupId, globalSchemaSpa }) {
  const classes = useStyles(globalSchemaSpa);
  const [dialogContent, setDialogContent] = React.useState('');
  const [optionName, setOptionName] = useState('');

  useEffect(() => {
    if(!healthWorkerGroupId) return undefined 

    const group = healthWorkerGroups.find(item => item.id === parseInt(healthWorkerGroupId));
    if(group) setOptionName(group.name);
  }, [healthWorkerGroupId]);

  const [open, setOpen] = React.useState(false);
  const handleClose = (value) => {
    setOpen(false);
  };

  function toggleModal(content) {
    if(open){
      setOpen(false);
    } else {
      setOpen(true);
      setDialogContent(content);
    }
  }

  function handleAutoCompleteChange(event, option) {
    if(!option) return undefined;
    
    const group = healthWorkerGroups.find(item => item.name === option);
    setDialogContent(group.description);
    setHealthWorkerGroupId(group.id);
    changeRequiredFields(group.required_fields_json);
    toggleModal(group.description);
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Typography style={{ fontSize: '14px', marginBottom: '10px', fontWeight: '600', fontFamily: 'Titillium Web, sans-serif' }}>A lista abaixo segue a ordem de priorização definida pelo Ministério da Saúde. Caso você se enquadre em mais de uma opção, escolha a primeira na ordem dos grupos prioritários estabelecidos no Plano Nacional de Vacinação.</Typography>
        <Autocomplete
          noOptionsText={'Sem registros'}
          clearOnEscape
          includeInputInList
          id="combo-box-demo"
          options={healthWorkerGroups.map(h => h.name)}
          onChange={handleAutoCompleteChange}
          value={optionName}
          error={userErrors.health_worker_group}
          getOptionSelected={(option, val) => val === option}
          getOptionLabel={(option) => option}
          style={{ width: '100%' }}
          renderInput={(params) => <TextField {...params} label="SELECIONE O SEU GRUPO"  fullWidth  required margin="normal"/>}
        />
        <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title"><Typography className={classes.dialogHeader}>ATENÇÃO</Typography></DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography component={'span'} dangerouslySetInnerHTML={{ __html: dialogContent }} className={classes.dialogContent} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className={`${classes.whiteBg} ${classes.customBtn}`} onClick={handleClose}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <p className="MuiFormHelperText-root-311 Mui-error Mui-required" style={{ color: '#f44336', marginTop: 0 }}>{userErrors.health_worker_group}</p>
      </div>
    </ThemeProvider>
  );
}