import React, { useState } from 'react';
import { 
  Container,
  Grid,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom'
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { toast } from 'react-toastify'
import GenericCard from './components/GenericCard.js';
import axios from 'axios';



const theme = createMuiTheme({
  overrides: {
    MuiInputLabel: {
      root: {
        color: 'black',
        fontSize: '13px',
        fontWeight: 500
      },
    },
    MuiFormLabel: {
      root: {
        '&.Mui-focused': {
          color: 'black',
          fontWeight: 500,
          fontSize: '14px'
        }
      }
    },
    MuiFormHelperText :{
      root: {
        color: 'black',
        fontSize: '13px'
      }
    },
    MuiInput: {
      root: {
        fontSize: '13px',
        color: 'black',
      },
      input: {
        textTransform: 'uppercase',
        borderBottom: '3px solid black',
        color: 'black'
      },
      underline: {
        color: 'black',
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: '3px solid black',
        },
        "&::before": {
          borderBottom: 'none'
        },
        "&::after": {
          borderBottom: 'none'
        }
      }
    }
  }
})
const useStyles = makeStyles((theme) => ({
  root: {
    '& a': {
      cursor: 'pointer',
      textDecoration: 'none',
      color: 'black',
      '&:hover': {
        textDecoration: 'underline',
        color: 'black',
      }
    }
  },
  quote: {
    '& blockquote': {
      borderLeft: props => `5px solid ${props.secondary_color}`,
      paddingLeft: '0.8em',
      fontFamily: "Titillium Web,sans-serif",
      fontSize: '1.6em',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '1.3',
      letterSpacing: '0em',
      marginRight: 'calc(0em * -1)',
      textTransform: 'none',
      transitionTimingFunction: 'cubic-bezier(0.400,0.000,0.200,1.000)',   
      '@media(max-width: 425px)': {
        fontSize: '1.3em',
      },
      
    },
  },
  buttonBack: {
    textTransform: 'none',
    fontSize: '16px',
    fontFamily: 'IntroBoldAlt, sans-serif',
    color: '#08558D',
    alignSelf: 'center',
    fontWeight: 'bold',
    width: '10%',
    display: 'block',
    marginBottom: '10px'
  },
  linkUnderline: {
    fontWeight: 600,
    marginBottom: 15,
  },
  customBtn: {
    fontFamily: "Titillium Web, sans-serif",
    borderRadius: '0.35em',
    transitionTimingFunction: 'cubic-bezier(0.400,0.000,0.200,1.000)',
    transitionDuration: '0.3s',
    boxShadow: 'none',
    border: '0px',
    fontSize: '1.11em',
    fontStyle: 'normal',
    fontWeight: '600 !important',
    lineHeight: '1.3',
    width: '334px',
    height: '51px',
    margin: '15px 0px',
    '&.Mui-disabled': {
      opacity: '0.7',
    },
    '@media(max-width: 425px)': {
      width: '100%',
    },
  },
  primaryBg: {
    color: 'white !important',
    backgroundColor: props => props.primary_color,
    borderColor:'#0000 #0000 #50b848 #0000',
    '&:hover': {
      backgroundColor: '#292929',
      color: 'white !important',
    }
  },

  dialogHeader: {
    fontSize: '2.5rem',
    fontFamily: 'Titillium Web,sans-serif',
    borderStyle: 'solid solid solid solid',
    borderWidth: '0px 0px 6px 0px',
    borderColor: props => `#00000000 #00000000 ${props.secondary_color}`
  },
}));

export default function RequestPasswordReset({ globalSchemaSpa }) {
  const classes = useStyles(globalSchemaSpa);
  const [numberField, setNumberField] = useState('');
  const [errors, setErrors] = useState({});  
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [numberIsCPF, setNumberIsCPF] = useState(true);

  const [open, setOpen] = React.useState(false);

  const handleOpen = (event) => {
    setOpen(true);
  };
  const handleClose = (value) => {
    setOpen(false);
    window.location.href = '/';
  };

  function resetPassword(e) {
    e.preventDefault();
    document.activeElement.blur();
    setLoading(true);
    axios.post('/health_workers/recuperar-senha', {health_worker: {login: numberField, login_provider: (numberIsCPF? 'cpf': 'cartao_sus')}})
      .then(function(){
        handleOpen();
      })
      .catch(function({ response = {}}){
        if(response && response.status === 422) {
          setErrors(response.data.errors);
        } else {
          toast.error('Não foi possível processar sua solicitação. Tente novamente em instantes.')
        }
      })
      .then(function() {
        setLoading(false);
      });
  }

  return (
    <ThemeProvider theme={theme}>
      <Container className={classes.root}>
            <Grid container>
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Button className={classes.buttonBack} onClick={() => history.push({ pathname: '/login' })}>Voltar</Button>
              </Grid>
            </Grid>
            <GenericCard color={"#000"}  borderColor={"#000"}  backgroundColor={"white"} title={"ENTRAR"}  titleLineColor={globalSchemaSpa.secondary_color}>
              <form className={classes.form} onSubmit={resetPassword}  noValidate>
                <Grid container >
                    <Grid item xs={12}>
                      <div className={classes.quote} >
                        <blockquote>Digite o CPF ou CNS para receber as instruções</blockquote>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        label={numberIsCPF ? 'CPF (APENAS NÚMEROS)': 'CNS (APENAS NÚMEROS)'}
                        value={numberField}
                        error={!!errors.email}
                        helperText={errors.email}
                        onChange={(e) => setNumberField(e.target.value)}
                        autoComplete="off"
                        autoFocus
                      />           
                    </Grid>
                    <Grid item xs={12}>
                      <a onClick={() => setNumberIsCPF(!numberIsCPF)} >{numberIsCPF ? 'Clique aqui para entrar com o Nº do Cartão SUS': 'Clique aqui para entrar com o CPF'}</a>         
                    </Grid>
                
            
                    <Grid item xs={12} style={{textAlign: 'right'}}>
                      <Button 
                        className={`${classes.primaryBg} ${classes.customBtn}`} 
                        type="submit" 
                        disabled={loading}
                      >
                        {loading ? 'AGUARDE...' : 'ENTRAR'}
                      </Button>   
                    </Grid>
                  </Grid>
              </form>
                
            </GenericCard>  
            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title"><Typography className={classes.dialogHeader}>ATENÇÃO</Typography></DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <p style={{fontSize: '1rem', fontFamily: 'Titillium Web,sans-serif', overflowY: 'auto',color: '#000'}}>Um e-mail será enviado para você ou seu responsável com as instruções de como trocar sua senha. Cheque sua caixa de entrada e caixa de spam em alguns instantes.</p>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button className={`${classes.primaryBg} ${classes.customBtn}`} onClick={handleClose}>
                  FECHAR
                </Button>
              </DialogActions>
            </Dialog>    
          </Container> 
    </ThemeProvider>
  );
}
