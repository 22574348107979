import React, { useState, useRef, useEffect } from 'react';
import { 
  Container,
  Grid,
  Button,
  TextField,
  Box
} from '@material-ui/core';
import { useHistory, Link } from 'react-router-dom'
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import AuthorizationClient from '../single-page/api/AuthorizationClient.js'
import { toast } from 'react-toastify'
import GenericCard from './components/GenericCard.js';
import { legacyMaskCPF, legacyMaskCartaoSus } from './components/TextMaskCustom';

const theme = createMuiTheme({
  overrides: {
    MuiInputLabel: {
      root: {
        color: 'black',
        fontSize: '13px',
        fontWeight: 500
      },
    },
    MuiFormLabel: {
      root: {
        '&.Mui-focused': {
          color: 'black',
          fontWeight: 500,
          fontSize: '14px'
        }
      }
    },
    MuiFormHelperText :{
      root: {
        color: 'black',
        fontSize: '13px'
      }
    },
    MuiInput: {
      root: {
        fontSize: '13px',
        color: 'black',
      },
      input: {
        borderBottom: '3px solid black',
        color: 'black'
      },
      underline: {
        color: 'black',
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: '3px solid black',
          // .MuiInput-underline-160:hover:not(.Mui-disabled):before
        },
        "&::before": {
          borderBottom: 'none'
        },
        "&::after": {
          borderBottom: 'none'
        }
      }
    }
  }
})
const useStyles = makeStyles((theme) => ({
  root: {
    '& a': {
      cursor: 'pointer',
      textDecoration: 'none',
      color: 'black',
      '&:hover': {
        textDecoration: 'underline',
        color: 'black',
      }
    }
  },
  quote: {
    '& blockquote': {
      borderLeft: props => `5px solid ${props.secondary_color}`,
      paddingLeft: '0.8em',
      fontFamily: "Titillium Web,sans-serif",
      fontSize: '1.6em',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '1.3',
      letterSpacing: '0em',
      marginRight: 'calc(0em * -1)',
      textTransform: 'none',
      transitionTimingFunction: 'cubic-bezier(0.400,0.000,0.200,1.000)',   
      '@media(max-width: 425px)': {
        fontSize: '1.3em',
      },
      
    },
  },
  buttonBack: {
    textTransform: 'none',
    fontSize: '16px',
    fontFamily: 'IntroBoldAlt, sans-serif',
    color: '#08558D',
    alignSelf: 'center',
    fontWeight: 'bold',
    width: '10%',
    display: 'block',
    marginBottom: '10px'
  },
  linkUnderline: {
    fontWeight: 600,
    marginBottom: 15,
  },
  customBtn: {
    borderRadius: '0.35em',
    fontFamily: "Titillium Web, sans-serif",
    transitionTimingFunction: 'cubic-bezier(0.400,0.000,0.200,1.000)',
    transitionDuration: '0.3s',
    boxShadow: 'none',
    border: '0px',
    fontSize: '1.11em',
    fontStyle: 'normal',
    fontWeight: '600 !important',
    lineHeight: '1.3',
    width: '334px',
    height: '51px',
    margin: '15px 0px',
    '&.Mui-disabled': {
      opacity: '0.7',
    },
    '@media(max-width: 425px)': {
      width: '100%',
    },
  },
  primaryBg: {
    color: 'white !important',
    backgroundColor: props => props.primary_color,
    borderColor:'#0000 #0000 #50b848 #0000',
    '&:hover': {
      backgroundColor: '#292929',
      color: 'white !important',
    }
  },
}));

export default function SignIn({ globalSchemaSpa }) {
  const classes = useStyles(globalSchemaSpa);
  let history = useHistory();
  
  
  const cpfRef = useRef('');
  const cnsRef = useRef('');
  const passwordRef = useRef('')
  const [numberFieldError, setNumberFieldError] = useState();
  const [passwordError, setPasswordError] = useState();
  const [loading, setLoading] = useState(false);
  const [numberIsCPF, setNumberIsCPF] = useState(true);


  useEffect(() => {
    legacyMaskCPF(cpfRef);
    legacyMaskCartaoSus(cnsRef);
  }, [numberIsCPF]);

  function sign_in(e) {
    e.preventDefault();
    document.activeElement.blur();
    toast.dismiss();
    setLoading(true);

    const cpfField = cpfRef.current.value
    const cnsField = cnsRef.current.value
    const password = passwordRef.current.value

    AuthorizationClient.signIn({login: (numberIsCPF? cpfField : cnsField), password: password , login_provider: (numberIsCPF? 'cpf': 'cartao_sus')})
      .then(function(){
        window.location.href = '/site'
      })
      .catch(function(error){
        toast.error('Erro ao processar solicitação.');
        const response = error.response || {};
        if (response.status == 401){
          setNumberFieldError('Login ou senha inválida.');
          setPasswordError('Login ou senha inválida.');
        } else {
          setNumberFieldError('');
          setPasswordError('');
          // Tratar algum outro erro
        }
      })
      .then(function() {
        setLoading(false);
      });
  }

  return (
    <ThemeProvider theme={theme}>
      <Container className={classes.root}>
            <Grid container>
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Button className={classes.buttonBack} onClick={() => history.push({ pathname: '/' })}>Voltar</Button>
              </Grid>
            </Grid>

            <GenericCard color={"#000"}  borderColor={"#000"}  backgroundColor={"white"} title={"ENTRAR"}  titleLineColor={globalSchemaSpa.secondary_color}>
              <form className={classes.form} onSubmit={sign_in}  noValidate>
                <Grid container >
                    <Grid item xs={12}>
                      <div className={classes.quote} >
                        <blockquote>Digite seus dados para continuar</blockquote>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Box display={numberIsCPF ? 'block' : 'none'}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="cpf"
                          label='CPF (APENAS NÚMEROS)'
                          error={numberFieldError}
                          inputRef={cpfRef}
                          visibility={numberIsCPF}
                          helperText={numberFieldError}
                          autoComplete="off"
                          autoFocus
                        />
                      </Box>
                      <Box display={!numberIsCPF ? 'block' : 'none'}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="cns"
                          visibility={!numberIsCPF}
                          label='CNS (APENAS NÚMEROS)'
                          error={numberFieldError}
                          inputRef={cnsRef}
                          helperText={numberFieldError}
                          autoComplete="off"
                          autoFocus
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <a onClick={() => setNumberIsCPF(!numberIsCPF)} >{numberIsCPF ? 'Clique aqui para entrar com o Nº do Cartão SUS': 'Clique aqui para entrar com o CPF'}</a>         
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        inputRef={passwordRef}
                        error={passwordError}
                        helperText={passwordError}
                        label="Senha"
                        type="password"
                        autoComplete="off"
                      />       
                    </Grid>
                    <Grid item xs={12}>
                      <Link to="/recuperar-senha" className={classes.linkUnderline}>
                        Esqueci minha senha
                      </Link>
                    </Grid>
                    <Grid item xs={12} style={{textAlign: 'right'}}>
                      <Button 
                        className={`${classes.primaryBg} ${classes.customBtn}`} 
                        type="submit" 
                        disabled={loading}
                      >
                        {loading ? 'AGUARDE...' : 'ENTRAR'}
                      </Button>   
                    </Grid>
                  </Grid>
              </form>
                
            </GenericCard>      
          </Container> 
    </ThemeProvider>
       
    // <div className={classes.paper}>
    //   <Avatar className={classes.avatar}>
    //     <LockOutlinedIcon />
    //   </Avatar>
    //   <Typography component="h1" variant="h5">
    //     Entrar
    //   </Typography>
    //   <form className={classes.form} noValidate>
    //     <TextField
    //       variant="outlined"
    //       margin="normal"
    //       required
    //       fullWidth
    //       label="Email"
    //       value={email}
    //       error={emailError}
    //       helperText={emailError}
    //       onChange={(e) => setEmail(e.target.value)}
    //       autoComplete="email"
    //       autoFocus
    //     />
    //     <TextField
    //       variant="outlined"
    //       margin="normal"
    //       required
    //       fullWidth
    //       value={password}
    //       error={passwordError}
    //       helperText={passwordError}
    //       onChange={(e) => setPassword(e.target.value)}
    //       label="Senha"
    //       type="password"
    //       autoComplete="current-password"
    //     />
    //     <FormControlLabel
    //       control={<Checkbox value="remember" color="primary" />}
    //       label="Lembre-se de mim"
    //     />

    //     <LoadingButton
    //       type="submit"
    //       fullWidth
    //       loading={loading}
    //       variant="contained"
    //       color="primary"
    //       className={classes.submit}
    //     >
    //       Entrar
    //     </LoadingButton>
    //   </form>
    //   <Grid container>
    //     <Grid item xs>
    //       <Link to="/public/recuperar-senha" variant="body2">
    //         Esqueceu sua senha?
    //       </Link>
    //     </Grid>
    //     <Grid item>
    //       <Link variant="body2">
    //         Não possui uma conta? Cadastre-se
    //       </Link>
    //     </Grid>
    //   </Grid>
    // </div>
  );
}