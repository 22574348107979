import React, { useState } from 'react';
import { Grid, Button, Container } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import GenericCard, { PrimaryCard } from '../components/GenericCard';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  content: {
    '& h3':{
      color: 'white',
      backgroundColor: props => props.secondary_color,
      display: 'flex',
      width: 'fit-content',
      padding: '7px 8px',
      marginBottom: '20px',
      marginTop: '0px',
      fontWeight: '400',
      letterSpacing: '-1px',
    },
    '& blockquote': {
      borderLeft: props => `5px solid ${props.secondary_color}`,
      paddingLeft: '0.8em',
      marginLeft: '2px'
    },
    '& p, h3, blockquote': {
      fontFamily: "Titillium Web,sans-serif",
      fontSize: '1.6em',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '1.3',
      letterSpacing: '0em',
      marginRight: 'calc(0em * -1)',
      textTransform: 'none',
      transitionTimingFunction: 'cubic-bezier(0.400,0.000,0.200,1.000)',
    },
    '& a': {
      color: 'white',
      textDecoration: 'underline'
    },
    '& a:hover': {
      color: 'white'
    }
  },
  customBtn: {
    borderRadius: '0.35em',
    fontFamily: "Titillium Web, sans-serif",
    transitionTimingFunction: 'cubic-bezier(0.400,0.000,0.200,1.000)',
    transitionDuration: '0.3s',
    boxShadow: 'none',
    border: '0px',
    fontSize: '1.11em',
    fontStyle: 'normal',
    fontWeight: '600 !important',
    lineHeight: '1.3',
    width: '334px',
    height: '51px',
    marginBottom: 15,
    '@media(max-width: 425px)': {
      width: 160,
    },
  },
  actButton: {
    color: 'white !important',
    backgroundColor: props => props.secondary_color,
    borderColor: '#33852d',      
    '&:hover': {
      backgroundColor: '#292929',
      color: 'white !important',
    }
  },
  whiteBg: {
    backgroundColor: 'white',
    color: props => props.primary_color,
    '&:hover': {
      backgroundColor: '#292929',
      color: 'white !important',
    }
	},
	moreContent: {
		fontFamily: "Titillium Web, sans-serif",
		color: 'white',
		fontSize: '1rem',
		fontWeight: 'bold',
		marginBottom: 20,
		display: 'block'
	}



});

export default function Index({ homeContent, homeContentExtra, globalSchemaSpa }) {
  const classes = useStyles(globalSchemaSpa);
  let history = useHistory(); 
	const [moreContent, setMoreContent] = useState(false)

	function toggleMoreContent() {
		setMoreContent(prev => !prev);
	}
  
  return (
    <Container>
      <GenericCard color={"#fff"} borderColor={globalSchemaSpa.primary_color} backgroundColor={globalSchemaSpa.primary_color} title={"PÚBLICO E VACINAÇÃO"} titleLineColor={globalSchemaSpa.secondary_color}>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.content}>
              <h3 style={{ margin: '0 auto', background: 'none', fontWeight: 'bold', textAlign: 'center' }}>VACINA SEM AGENDAMENTO</h3>
              <p style={{ textAlign: 'center' }}>
                Temos uma ótima notícia: o agendamento prévio para a vacinação contra a Covid-19 NÃO é mais necessário!
                Agora, para receber sua dose, basta selecionar o local mais próximo e conveniente para você. Simples assim:
                escolha, compareça e proteja-se!
              </p>
              <p style={{ textAlign: 'center' }}>
                Para facilitar ainda mais, disponibilizamos abaixo a lista completa dos locais de vacinação.
                Confira e mantenha sua imunização em dia. A saúde é nosso bem mais precioso, e juntos,
                podemos continuar avançando na luta contra a Covid-19.
              </p>
              <Grid container>
                <Grid item xs={12} md={12} style={{textAlign: 'center'}}>
                  <Link to="/locais-sem-agendamento" style={{ textDecoration: 'none' }}>
                    <Button className={`${classes.actButton} ${classes.customBtn}`}>
                      VER LISTA COMPLETA
                    </Button>
                  </Link>
                </Grid>
              </Grid>

              {/* <h3 style={{ margin: '0 auto', background: 'none', fontWeight: 'bold', marginTop: 35, textAlign: 'center' }}>VACINA COM AGENDAMENTO</h3>
              <p style={{ textAlign: 'center' }}>Caso prefira a sua vacinação com agendamento, acesse o sistema ou cadastre-se:</p> */}
            </div>

            <Grid container>
              {/* <Grid item xs={12} md={6} style={{textAlign: 'center'}}>
                <Button className={`${classes.actButton} ${classes.customBtn}`}  onClick={() => history.push({pathname: '/registro'})}>
                  CADASTRAR
                </Button>
              </Grid> */}
              <Grid item xs={12} md={6} style={{textAlign: 'center'}}>
                <Button className={`${classes.whiteBg} ${classes.customBtn}`} onClick={() => history.push({pathname: '/login'})} >
                  ENTRAR/EMITIR CERTIFICADO
                </Button>
              </Grid>
            </Grid>

            {/* <div className={classes.content} style={{ marginTop: 35 }}>
              <h3 style={{ margin: '0 auto', background: 'none', fontWeight: 'bold', marginTop: 35, textAlign: 'center' }}>MAIS INFORMAÇÕES</h3>
              <p style={{ textAlign: 'center' }}>Para saber mais informações sobre a vacinação contra a COVID-19 no município <a href="javascript:;" className={classes.moreContent} onClick={toggleMoreContent} style={{ display: 'inline' }}>CLIQUE AQUI</a></p>
            </div> */}

            <Collapse in={moreContent}>
              <div dangerouslySetInnerHTML={{ __html: homeContent }} className={classes.content}/>
              <div dangerouslySetInnerHTML={{ __html: homeContentExtra }} className={classes.content}/>
            </Collapse>
          </Grid>
        </Grid>
      </GenericCard>
    </Container>
  );
}
