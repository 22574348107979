import React, { useState } from 'react';

export default function registrationHOC(Component) {
  return (props) => {
    const [requiredFields, setRequiredFields] = useState({ extra_health_fields: [], attachments: {}, work_section_options: [], sub_groups: [] });
    const [documents, setDocuments] = useState({ 
      anexo_1: undefined, 
      anexo_1_signed_key: '', 
      anexo_2: undefined, 
      anexo_2_signed_key: '', 
      anexo_3: undefined, 
      anexo_3_signed_key: '', 
      anexo_4: undefined,
      anexo_4_signed_key: ''
    });
    const [documentErrors, setDocumentErrors] = useState({});
    const directUploadUrl = '/rails/active_storage/direct_uploads';
    
    function changeRequiredFields(rawFieldsJson) {
      try {
        const parsedRequiredFields = JSON.parse(rawFieldsJson)
        setRequiredFields(parsedRequiredFields);
      } catch (error) {
        setRequiredFields({});
      }
    }

    function activeStorageUpload(field) {
      if(!documents[field]) return undefined;
      if(documents[field].type === 'application/mock') return undefined;

      return new Promise((resolve, reject) => {
        const activeStorage = new ActiveStorage.DirectUpload(documents[field], directUploadUrl)
        activeStorage.create((error, attributes) => {
          if (error) reject(error);

          try {
            resolve(attributes.signed_id);
          } catch (err) {
            reject(err + ' Attributes: ' + JSON.stringify(attributes));
          }
        });
      });
    }

    async function createFileTransaction(file, name) {
      if(!file) return false;

      const valid = validateFile(name, file);
      if(!valid) return false;

      try {
        const fileReaded = await new Promise((resolve, reject) => {
          const reader = new FileReader(); 
          reader.onload = function (re) { 
            const { result } = re.target
            if(result) {
              const byteString = atob(result.split(',')[1]);
              const mimeString = result.split(',')[0].split(':')[1].split(';')[0];
              const ab = new ArrayBuffer(byteString.length);
              const ia = new Uint8Array(ab);

              for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
              }
              const blob =  new Blob([ab], { type: mimeString });

              resolve(new File([blob], file.name, { type: file.type }));
            } else {
              resolve(null); 
            }
          }; 
          reader.onerror = function (error) { 
            reject(error); 
          }; 
          reader.readAsDataURL(file);
        });

        if(fileReaded == null) throw new Error('File cannot be readed by FileReader')

        setDocuments({ ...documents, [name]: fileReaded, [name + '_signed_key']: '' });
        setDocumentErrors({ ...documentErrors, [name]: '' });

        return true;
        
      } catch (error) {
        setDocuments({ ...documents, [name]: undefined, [name + '_signed_key']: '' });
        setDocumentErrors({ ...documentErrors, [name]: 'O arquivo não pôde ser lido devido a permissões internas dos seus aplicativos. Tente um arquivo armazenado localmente em seu dispositivo.' });
        return false;
      }
    }

    function validateFile(name, file) {
      if (file.size > 8 * 1024 * 1024) {
        setDocumentErrors({ ...documentErrors, [name]: 'Tamanho máximo: 8MB' });
        return false;
      }

      return true;
    }

    async function uploadAndFetchAttachmentKeys(objForm) {
      const attachmentsArr = Object.keys(requiredFields.attachments);
      const keysObj = objForm;

      if(attachmentsArr.includes('anexo_1')) {
        if (keysObj.anexo_1_signed_key == '') {
          keysObj.anexo_1 = await activeStorageUpload('anexo_1');
          if (keysObj.anexo_1 != undefined)
            setDocuments({ ...documents, ['anexo_1_signed_key']: keysObj.anexo_1 });
        } else {
          keysObj.anexo_1 = keysObj.anexo_1_signed_key
        }
      }

      if(attachmentsArr.includes('anexo_2')) {
        if (keysObj.anexo_2_signed_key == '') {
          keysObj.anexo_2 = await activeStorageUpload('anexo_2');
          if (keysObj.anexo_2 != undefined)
            setDocuments({ ...documents, ['anexo_2_signed_key']: keysObj.anexo_2 });
        } else {
          keysObj.anexo_2 = keysObj.anexo_2_signed_key
        }
      }

      if(attachmentsArr.includes('anexo_3')) {
        if (keysObj.anexo_3_signed_key == '') {
          keysObj.anexo_3 = await activeStorageUpload('anexo_3');
          if (keysObj.anexo_3 != undefined)
            setDocuments({ ...documents, ['anexo_3_signed_key']: keysObj.anexo_3 });
        } else {
          keysObj.anexo_3 = keysObj.anexo_3_signed_key
        }
      }

      if(attachmentsArr.includes('anexo_4')) {
        if (keysObj.anexo_4_signed_key == '') {
          keysObj.anexo_4 = await activeStorageUpload('anexo_4');
          if (keysObj.anexo_4 != undefined)
            setDocuments({ ...documents, ['anexo_4_signed_key']: keysObj.anexo_4 });
        } else {
          keysObj.anexo_4 = keysObj.anexo_4_signed_key
        }
      }

      return keysObj;
    }

    function handleRemoveDocument(name) {
      setDocuments({ ...documents, [name]: undefined, [name + '_signed_key']: '' });
    }

    const hooks = { 
      documents, 
      setDocuments, 
      documentErrors, 
      setDocumentErrors,
      requiredFields,
    };

    return (
      <Component 
        activeStorageUpload={activeStorageUpload}
        changeRequiredFields={changeRequiredFields}
        createFileTransaction={createFileTransaction}
        handleRemoveDocument={handleRemoveDocument}
        uploadAndFetchAttachmentKeys={uploadAndFetchAttachmentKeys}
        {...hooks}
        {...props}
      />
    )
  }
}
