import React from 'react';
import { IMaskInput } from 'react-imask';
import moment from 'moment';
import IMask from 'imask';


function TextMaskCustom(props) {
  const { inputRef, ...other } = props;
  const { mask, extra } = routeMask(props.mask);

  return (
    <IMaskInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      onAccept={
        // Advance to the next field, if there is one, when mask is completed
        (value, mask) => {
          if (mask.masked.isComplete) {
            const form = mask.el.input.form;
            const index = Array.prototype.indexOf.call(form, mask.el.input);
            if (form.elements.length - 1 > index) {
              form.elements[index + 1].focus();
            }
          }
        }
      }
      mask={mask}
      {...extra}
      // showMask={false}
      // placeholderChar={"\u2000"}
    />
  );
}

function routeMask(maskLabel) {
  let mask;
  let extra;
  
  switch (maskLabel) {
    case 'cpf':
      mask = '000.000.000-00'
      break;
    case 'cartao_sus':
      mask = '000 0000 0000 0000'
      break;
    case 'email':
      mask = /^[a-zA-Z0-9|\s|@|\\.|\-|\_]+$/;
      break;
    case 'phone':
      mask = '(00) 00000-0000'
      break;
    case 'date':
      mask = '00/00/0000';
      extra = {
        lazy: false,
        overwrite: true,
        validate: (value, masked) => {
          const errors = [];

          if(value.match(/^\d\d\/\d\d\/\d\d\d\d$/) != null) {
            const currentDate = moment(value, 'DD-MM-YYYY');
            if(!currentDate.isValid()) errors.push('invalid');
            if(!currentDate.isBetween('1900-01-01', undefined)) errors.push('out_of_range');
          }

          return errors.length === 0;
        },
      }
      break;
    case 'zipCode':
      mask = '00000-000'
      break;
    default:
      break;
  }

  return { mask, extra };
}

export function TextMaskCPF(props){
  return <TextMaskCustom {...props} mask='cpf' />
}

export function TextMaskCartaoSus(props) {
  return <TextMaskCustom {...props} mask='cartao_sus' />
}

export function TextMaskPhone(props){
  return <TextMaskCustom {...props} mask='phone' />
}

export function TextMaskDate(props){
  return <TextMaskCustom {...props} mask='date' />
}

export function TextMaskZipCode(props){
  return <TextMaskCustom {...props} mask='zipCode' />
}

export function legacyMaskCPF(elementRef) {
  const { mask, extra = {} } = routeMask('cpf');

  return IMask(elementRef.current, { mask, ...extra });
}

export function legacyMaskCartaoSus(elementRef) {
  const { mask, extra = {} } = routeMask('cartao_sus');

  return IMask(elementRef.current, { mask, ...extra });
}

export function legacyMaskPhone(elementRef) {
  const { mask, extra = {} } = routeMask('phone');

  return IMask(elementRef.current, { mask, ...extra });
}

export function legacyMaskDate(elementRef) {
  const { mask, extra = {} } = routeMask('date');

  return IMask(elementRef.current, { mask, ...extra });
}

export function legacyMaskZipCode(elementRef) {
  const { mask, extra = {} } = routeMask('zipCode');

  return IMask(elementRef.current, { mask, ...extra });
}

export function legacyMaskRegex(elementRef, regex) {
  return IMask(elementRef.current, { mask: regex });
}

export function legacyMaskEmail(elementRef) {
  const { mask, extra = {} } = routeMask('email');
  return IMask(elementRef.current, { mask, ...extra });
}