import React, { useState, useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Button, Container, Typography } from '@material-ui/core';
import AllowedField from '../components/AllowedField';
import FileInput from '../components/FileInput';

const useStyles = makeStyles({
  root: {

  },
  gridDoc: {
    textAlign: 'center',
    '@media(max-width: 425px)': {
      margin: '20px 0px'
    }
  },
  buttonLabel: {
    fontSize: '13px',
    fontWeight: 600,
    marginBottom: '5px',
    marginTop: '2px',
    textTransform: 'uppercase',
    fontFamily: 'Titillium Web, sans-serif',
  },
  buttonLabelError: {
    fontSize: '13px',
    fontWeight: 600,
    marginBottom: '5px',
    color: '#f44336',
    marginTop: '2px',
    fontFamily: 'Titillium Web, sans-serif',
  },
  customBtn: {
    borderRadius: '2px',
    fontFamily: "Titillium Web, sans-serif",
    transitionTimingFunction: 'cubic-bezier(0.400,0.000,0.200,1.000)',
    transitionDuration: '0.3s',
    boxShadow: 'none',
    fontFamily: 'Titillium Web, sans-serif',
    border: 0,
    fontSize: 12,
    fontWeight: 400,
    padding: 0,
    fontStyle: 'normal',
    lineHeight: 1.5,
    width: 155,
    height: 30,
  },
  greenBg: {
    color: 'white !important',
    backgroundColor: '#4fb848',
    borderColor: '#33852d',      
    '&:hover': {
      backgroundColor: '#292929',
      color: 'white !important',
    }
  }
});

export default function Documents({ documents, documentErrors, createFileTransaction, handleRemoveDocument, requiredAttachments }) {
  const classes = useStyles({});
  
  return (
    <Container>
      <Grid container>
        <AllowedField allowed={requiredAttachments.anexo_1}>
          <Grid item xs={12} md={6} className={classes.gridDoc}>
            <FileInput 
              label={requiredAttachments.anexo_1}
              inputName="anexo_1"
              currentFile={documents.anexo_1}
              signedKey={documents.anexo_1_signed_key}
              createFileTransaction={createFileTransaction}
              handleRemoveDocument={handleRemoveDocument}
              errorMsg={documentErrors.anexo_1}
              clsName={`${classes.greenBg} ${classes.customBtn}`}
              classes={classes}
            />
          </Grid>
        </AllowedField>
        <AllowedField allowed={requiredAttachments.anexo_2}>
          <Grid item xs={12} md={6} className={classes.gridDoc}>
            <FileInput 
              label={requiredAttachments.anexo_2}
              inputName="anexo_2"
              currentFile={documents.anexo_2}
              signedKey={documents.anexo_2_signed_key}
              createFileTransaction={createFileTransaction}
              handleRemoveDocument={handleRemoveDocument}
              errorMsg={documentErrors.anexo_2}
              clsName={`${classes.greenBg} ${classes.customBtn}`}
              classes={classes}
            />
          </Grid>
        </AllowedField>
        <AllowedField allowed={requiredAttachments.anexo_3}>
          <Grid item xs={12} md={6} className={classes.gridDoc}>
            <FileInput 
              label={requiredAttachments.anexo_3}
              inputName="anexo_3"
              currentFile={documents.anexo_3}
              signedKey={documents.anexo_3_signed_key}
              createFileTransaction={createFileTransaction}
              handleRemoveDocument={handleRemoveDocument}
              errorMsg={documentErrors.anexo_3}
              clsName={`${classes.greenBg} ${classes.customBtn}`}
              classes={classes}
            />
          </Grid>
        </AllowedField>
        <AllowedField allowed={requiredAttachments.anexo_4}>
          <Grid item xs={12} md={6} className={classes.gridDoc}>
            <FileInput 
              label={requiredAttachments.anexo_4}
              inputName="anexo_4"
              currentFile={documents.anexo_4}
              signedKey={documents.anexo_4_signed_key}
              createFileTransaction={createFileTransaction}
              handleRemoveDocument={handleRemoveDocument}
              errorMsg={documentErrors.anexo_4}
              clsName={`${classes.greenBg} ${classes.customBtn}`}
              classes={classes}
            />
          </Grid>
        </AllowedField>
      </Grid>
    </Container>
  );
}