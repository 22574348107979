import React from 'react';
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import bgWaves from '../images/bg-waves.svg'
import logoConectaRecife from '../images/logo-conecta-recife.svg'
import logo from '../images/logo.png'

const useStyles = makeStyles({
  root: {
    backgroundImage: `url('${bgWaves}')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundColor: props => props.bgColor,
    height: '225px',
    display: 'flex',
    justifyContent: 'center'
  },
  vacinaLogo: {
    height: '104.56px',
    display: 'block',
    margin: '0 auto',
    marginTop: '2.5em',
  },
  marcaGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '600px',
  },
  conectaRecife: {
    textAlign: 'center',
    padding: '0px 10px',
  },
  logoConectaRecife: {
    maxHeight: '140px',
    '@media(max-width: 425px)': {
      maxWidth: '100%',
    }
  },
  logo: {
    textAlign: 'center',
    width: '50%',
    height: '161.28px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media(max-width: 425px)': {
      height: '104.28px'
    }
  },
  logoRecife: {
    maxHeight: '100px',
    '@media(max-width: 425px)': {
      height: '45px'
    }
  }
});

export default function Header(props) {
  const classes = useStyles(props);

  return (
    <Grid container className={classes.root}>
      <div className={classes.marcaGrid}>
        <div className={classes.conectaRecife}>
          <img className={classes.logoConectaRecife} src={props.imageUrl}/>
        </div>
      </div>
    </Grid>
  );
}