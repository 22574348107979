import React from 'react';
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import logoFooter from '../images/logo.png'

const useStyles = makeStyles({
  root: {
    height: '217px',
    backgroundColor: props => props.bgColor,
    color: 'white',
    bottom: 0,
    textAlign: 'center',
    width: '100%',
    paddingTop: '35px',
    paddingBottom: '15px',
    display: 'block'
  },
  logoFooter: {
    height: '100px',
    marginBottom: '10px',
    '@media(max-width: 425px)': {
      height: '65px'
    }
  }
});

export default function Footer(props) {
  const classes = useStyles(props);

  return (
    <Grid container className={classes.root} id="footer">
      <img className={classes.logoFooter} src={props.imageUrl}/>
      <p style={{ fontWeight: 600 }}>Todos os direitos reservados</p>
    </Grid>
  );
}