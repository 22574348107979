import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  menuItem: {
    fontSize: '0.8rem',
    whiteSpace: 'inherit',
    fontFamily: "Titillium Web, sans-serif",
  },
}));

const selectStyle = makeStyles(() => ({
  selectMenu: {
    whiteSpace: 'initial',
  }
}));

export default function CommomSelect({ onChange, defaultValue = '', items, name }) {
  const classes = useStyles({});
  const selectClasses = selectStyle();

  return (
    <Select
      name={name}
      classes={{ selectMenu: selectClasses.selectMenu }}
      inputProps={{ 'aria-label': 'Without label' }}
      defaultValue={defaultValue}
      onChange={onChange}
    >
      {items.map(item => (<MenuItem disableRipple className={classes.menuItem} value={item.value}>{item.label}</MenuItem>))}
    </Select>
  );
}
