import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import MaterialTable, { MTableToolbar } from 'material-table';
import { tableIcons } from '../components/tableIcons.js';
import GenericCard from '../components/GenericCard.js';
import {
  Container,
  Grid,
  Button,
  TextField,
  Box
} from '@material-ui/core';
import axios from 'axios';

const columns = [
  {
    title: 'Local',
    field: 'name',
    filtering: false,
  },
  {
    title: 'Público',
    field: 'public_type',
  },
  {
    title: 'Bairro',
    field: 'neighborhood',
  },
  {
    title: 'Endereço',
    field: 'address',
    filtering: false,
    sorting: false,
  },
  {
    title: 'Observações',
    field: 'note',
    filtering: false,
    sorting: false,
  },
];

const useStylesToolbar = makeStyles(() => ({
  root: {
    '@media(max-width: 768px)': {
      flexDirection: 'column',
      paddingTop: 15,
      paddingBottom: 15,
    },
  },
  title: {
    '@media(max-width: 768px)': {
      marginBottom: 15,
    }
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    '& a': {
      cursor: 'pointer',
      textDecoration: 'none',
      color: 'black',
      '&:hover': {
        textDecoration: 'underline',
        color: 'black',
      }
    }
  },
  quote: {
    '& blockquote': {
      borderLeft: props => `5px solid ${props.secondary_color}`,
      paddingLeft: '0.8em',
      fontFamily: "Titillium Web,sans-serif",
      fontSize: '1.6em',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '1.3',
      letterSpacing: '0em',
      marginRight: 'calc(0em * -1)',
      textTransform: 'none',
      transitionTimingFunction: 'cubic-bezier(0.400,0.000,0.200,1.000)',   
      '@media(max-width: 425px)': {
        fontSize: '1.3em',
      },
      
    },
  },
  buttonBack: {
    textTransform: 'none',
    fontSize: '16px',
    fontFamily: 'IntroBoldAlt, sans-serif',
    color: '#08558D',
    alignSelf: 'center',
    fontWeight: 'bold',
    width: '10%',
    display: 'block',
    marginBottom: '10px'
  },
  linkUnderline: {
    fontWeight: 600,
    marginBottom: 15,
  },
  customBtn: {
    borderRadius: '0.35em',
    fontFamily: "Titillium Web, sans-serif",
    transitionTimingFunction: 'cubic-bezier(0.400,0.000,0.200,1.000)',
    transitionDuration: '0.3s',
    boxShadow: 'none',
    border: '0px',
    fontSize: '1.11em',
    fontStyle: 'normal',
    fontWeight: '600 !important',
    lineHeight: '1.3',
    width: '334px',
    height: '51px',
    margin: '15px 0px',
    '&.Mui-disabled': {
      opacity: '0.7',
    },
    '@media(max-width: 425px)': {
      width: '100%',
    },
  },
  primaryBg: {
    color: 'white !important',
    backgroundColor: props => props.primary_color,
    borderColor:'#0000 #0000 #50b848 #0000',
    '&:hover': {
      backgroundColor: '#292929',
      color: 'white !important',
    }
  },
}));

const theme = createMuiTheme({
  overrides: {
    MuiInputLabel: {
      root: {
        color: 'black',
        fontSize: '13px',
        fontWeight: 500
      },
    },
    MuiFormLabel: {
      root: {
        '&.Mui-focused': {
          color: 'black',
          fontWeight: 500,
          fontSize: '14px'
        }
      }
    },
    MuiFormHelperText :{
      root: {
        color: 'black',
        fontSize: '13px'
      }
    },
    MuiInput: {
      root: {
        fontSize: '13px',
        color: 'black',
      },
      input: {
        borderBottom: '3px solid black',
        color: 'black'
      },
      underline: {
        color: 'black',
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: '3px solid black',
          // .MuiInput-underline-160:hover:not(.Mui-disabled):before
        },
        "&::before": {
          borderBottom: 'none'
        },
        "&::after": {
          borderBottom: 'none'
        }
      }
    }
  }
})

export default function UnscheduledVaccinationSite({ globalSchemaSpa }) {
  const classes = useStyles(globalSchemaSpa);
  const toolbarClasses = useStylesToolbar();
  const [unscheduledVaccinationSites, setUnscheduledVaccinationSites] = useState();

  const history = useHistory();

  useEffect(() => {
    axios.get('/api/v1/unscheduled_vaccination_sites.json')
      .then((res) => {
        setUnscheduledVaccinationSites(res.data);
      })
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Grid container>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <a href="/" style={{ color: '#08558D', textDecoration: 'none' }}>
              <Button className={classes.buttonBack}>
                Voltar
              </Button>
            </a>
          </Grid>
        </Grid>

        <div style={{ marginBottom: 40 }}>
          <MaterialTable
            title="Locais sem agendamento"
            icons={tableIcons}
            columns={columns}
            data={unscheduledVaccinationSites}
            localization={{
              body: {
                emptyDataSourceMessage: 'Não há locais cadastrados',
                filterRow: {
                  filterTooltip: 'Filtrar',
                },
              },
              toolbar: {
                searchPlaceholder: 'Digite para procurar',
              },
            }}
            options={{
              filtering: true,
              paging: false,
              draggable: false,
            }}
            components={{
              Toolbar: props => (
                <MTableToolbar {...props} classes={toolbarClasses} />
              )
            }}
          />
        </div>
      </Container>
    </ThemeProvider>
  )
}
