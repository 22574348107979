import React, { useState, useRef, useEffect } from 'react'
import { Typography } from '@material-ui/core';

export default function FileInput(props) {
  const { classes, inputName, label, errorMsg, clsName, currentFile, createFileTransaction, signedKey } = props;

  const inputRef = useRef(null);
  const previewRef = useRef(null);
  const signedKeyInputRef = useRef(null);

  const [hasFile, setHasFile] = useState(false);
  const [fileName, setFileName] = useState(undefined);
  const [fileData, setFileData] = useState('');

  async function middFileChange(event) {
    const file = event.target.files[0];
    if(!file) return undefined;

    const inputName = event.target.name;
    const transactionCreated = await createFileTransaction(file, inputName);
    if(transactionCreated) {
      setHasFile(true);
      updatePreview(file);
    } else {
      setFileData('');
      setHasFile(false);
      setFileName(undefined);
    }
  }

  useEffect(() => {
    previewRef.current.src = fileData;
  }, [fileData]);

  // function removeFile() {
  //   const name = inputRef.current.name;
  //   setFileData('');
  //   setHasFile(false);
  //   setFileName(undefined);
  //   handleRemoveDocument(name);
  // }

  function updatePreview(file) {
    const formattedName = formatText(file.name)
    setFileName(formattedName);

    if(file.type === 'application/mock') {
      return undefined;
    }

    if(!file.type.match(/image\/*/)) {
      // previewRef.current.src = '/placeholder-file.jpg';
      setFileData('/placeholder-file.jpg');
      return undefined;
    }
    
    const reader = new FileReader()
    reader.onloadend = () => {
      setFileData(reader.result);
    };
    reader.readAsDataURL(file);
  }

  useEffect(() => {
    if(!currentFile) return undefined;

    setHasFile(true);
    updatePreview(currentFile);
  }, []);

  const flexBtnDiv = {
    display: 'flex',
    // justifyContent: 'space-between',
    justifyContent: 'center',
    width: 190,
    margin: '0 auto'
  }

  const removeButtonStyle = {
    width: 90,
    backgroundColor: 'crimson',
  };
  
  const changeButtonStyle = {
    width: 90,
  };

  function formatText(text) {
    if(!text) return '';

    if(text.length > 100) {
      return text.substring(0, 20).concat('...') + text.substring(text.length - 20)
    } 

    return text;
  }

  return (
    <>
      <Typography className={classes.buttonLabel}>{label}</Typography>

      <img ref={previewRef} style={{ maxHeight: 150, maxWidth: '250px', margin: '0 auto', marginBottom: 10, display: 'block' }} />
      {fileName && <p style={{ margin: 0, marginBottom: 5 }}>{fileName}</p>}
      <input type='hidden' ref={signedKeyInputRef} value={signedKey} name={inputName + '_signed_key'}></input>
      <input type='file' ref={inputRef} name={inputName} onChange={middFileChange} style={{ display: 'none' }}/>
      {
        hasFile ? 
          (<div style={flexBtnDiv}>
            <button type="button"  className={clsName} style={changeButtonStyle} onClick={() => inputRef.current.click()}>
              MUDAR
            </button>
            {/* <button type="button"  className={clsName} style={removeButtonStyle} onClick={removeFile}>
              REMOVER
            </button> */}
          </div>) :
          (<button type="button"  className={clsName} onClick={() => inputRef.current.click()}>
            SELECIONE O ARQUIVO
          </button>)
      }
      <Typography className={classes.buttonLabelError}>{errorMsg}</Typography>
    </>
  )
}