import React, { useEffect, useState } from 'react';
import { 
  CssBaseline, 
  Container
} from '@material-ui/core';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { createMuiTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles';
import SignIn from './SignIn.js'
import RequestPasswordReset from './RequestPasswordReset.js'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles/main.scss'
import Index from './pages/Index.js';
import Header from './components/Header.js';
import Registration from './pages/Registration.js';
import UnscheduledVaccinationSite from './pages/UnscheduledVaccinationSite.js';
import Footer from './components/Footer.js';
import ScrollToTop from '../components/ScrollToTop';


const signInTheme = createMuiTheme({
  props: {
    // Name of the component ⚛️
    MuiTextField: {
      // The default props to change
      margin: "normal",
      fullWidth: true,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  paper: {
    // marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Public({ flash, homeContent, homeContentExtra, global_schema_spa: globalSchemaSpa = { current_user: {}} }) {
  const classes = useStyles();
  const [currentUser, setCurrentUser] = useState(globalSchemaSpa.current_user);

  useEffect(() => {
    // Atualiza o titulo do documento usando a API do browser
    if (flash.notice) {
      toast.info(flash.notice);
    }
    if (flash.notice) {
      toast.warning(flash.alert);
    }
  }, []);

  return (
    <div>
      <ToastContainer autoClose={8000} />
      <Header bgColor={globalSchemaSpa.header_color} imageUrl={globalSchemaSpa.header_img} />
      <Container component="main" maxWidth="xl">
        <CssBaseline />
        <div style={{ textAlign: 'center', marginTop: 15, marginBottom: 30 }}>
          <img src={globalSchemaSpa.container_img} style={{ maxHeight: 85 }}/>
        </div>
        <ThemeProvider theme={signInTheme}>
          <div className={classes.paper}>
            <Router>
              <ScrollToTop />
              <Route exact path={"/"}>
                <Index homeContentExtra={homeContentExtra} homeContent={homeContent} globalSchemaSpa={globalSchemaSpa}/>
              </Route>
              <Route exact path={"/registro"}>
                <Registration healthWorkerGroups={globalSchemaSpa.health_worker_groups} globalSchemaSpa={globalSchemaSpa} />
              </Route>

              <Route exact path={"/login"}>
                <SignIn globalSchemaSpa={globalSchemaSpa} />
              </Route>
              <Route exact path="/recuperar-senha">
                <RequestPasswordReset globalSchemaSpa={globalSchemaSpa} />
              </Route>
              <Route exact path="/locais-sem-agendamento">
                <UnscheduledVaccinationSite globalSchemaSpa={globalSchemaSpa} />
              </Route>
            </Router>
          </div>
    
        </ThemeProvider>
      </Container>
      <Footer imageUrl={globalSchemaSpa.footer_img} bgColor={globalSchemaSpa.footer_color}/>
    </div>
  );
}
