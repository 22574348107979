import React, { useEffect, useState } from 'react';
import { Grid, Link, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import VacinationGroup from './VacinationGroup.js';
import Documents from './Documents.js';
import RegistrationForm from './RegistrationForm.js';
import registrationHOC from '../../components/registrationHOC';
import GenericCard from '../../public/components/GenericCard';
import axios from 'axios';
import { toast } from 'react-toastify';

const useStyles = makeStyles({
  root: {
    justifyContent: 'center',
    '@media(max-width: 425px)': {
      paddingBottom: '100px',
    },
  },
  buttonBack: {
    textTransform: 'none',
    fontSize: '16px',
    fontFamily: 'IntroBoldAlt, sans-serif',
    color: '#08558D',
    alignSelf: 'center',
    fontWeight: 'bold',
    width: '10%',
    display: 'block',
    marginBottom: '10px'
  }
});

function Registration({ 
    healthWorkerGroups,
    globalSchemaSpa,
    changeRequiredFields,
    createFileTransaction,
    handleRemoveDocument,
    uploadAndFetchAttachmentKeys,
    documents,
    documentErrors,
    setDocumentErrors,
    requiredFields,
  }) {

  const classes = useStyles(globalSchemaSpa);
  const history = useHistory();
  const [healthWorkerGroupId, setHealthWorkerGroupId] = useState('');
  const [user, setUser] = useState({ special_need: 'nao_possue', raca_cor: '', sexo: '', dependent: false })
  const [userErrors, setUserErrors] = useState({});
  const [saving, setSaving] = useState(false);
  
  async function signUp(e) {
    e.preventDefault();
    setSaving(true);
    
    const form = e.target
    const checkBoxFields = ['according_law', 'dependent'];
    
    const inputs = Array.from(form.elements).filter(el => el.type != "radio" && el.type != "button")
    const objForm = inputs.reduce((obj, input) => {
			if(input.name === '') return obj;

      if(checkBoxFields.includes(input.name)) {
        obj[input.name] = input.checked;
      } else {
        obj[input.name] = input.value;
      }

      return obj;
    }, {});

    try {
      await uploadAndFetchAttachmentKeys(objForm);
    } catch (error) {
      toast.error('Não foi possível fazer o upload dos seus anexos. Tente novamente em instantes');
      setSaving(false);
      // throw new Error(error);
      return null;
    }

    objForm.health_worker_group_id = healthWorkerGroupId;
    axios.post(`/health_workers.json`, { health_worker: objForm })
      .then(res => {
        setUserErrors({});
        setDocumentErrors({});
        toast.success('Cadastrado com sucesso!');
        window.location.reload();

        return { redirect: true }
      })
      .catch(({ response = {} }) => {
        if(response.status === 422) {
          const errorFields = {};
          for(const field in response.data) {
            errorFields[field] = response.data[field].join();
          }
          setUserErrors({ ...errorFields });
          setDocumentErrors({ ...errorFields });
          toast.error('Não foi possível cadastrar seus dados. Revise os campos e tente novamente.');
        }

        if(response.status === 400) {
          toast.error('Não foi possível cadastrar seus dados. Recarregue a página e tente novamente.');
        }
        return {}
      })
      .then(result => {
        if(result.redirect) return undefined;
        
        setSaving(false);
      });
  }

  return (
    <Grid container className={classes.root}>
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        <Button className={classes.buttonBack} onClick={() => history.push({ pathname: '/' })}>Voltar</Button>
        <form className={classes.form} onSubmit={signUp} noValidate>
					<GenericCard 
						borderColor={globalSchemaSpa.primary_color}
						backgroundColor={globalSchemaSpa.primary_color}
						title={'MARQUE SEU GRUPO PRIORITÁRIO'}
						titleLineColor={globalSchemaSpa.secondary_color}
					>
            <VacinationGroup
              changeRequiredFields={changeRequiredFields} 
              healthWorkerGroups={healthWorkerGroups}
              userErrors={userErrors}
              globalSchemaSpa={globalSchemaSpa}
              healthWorkerGroupId={healthWorkerGroupId}
              setHealthWorkerGroupId={setHealthWorkerGroupId} />
          </GenericCard>
          <GenericCard color={'black'} borderColor={'black'} backgroundColor={'transparent'} title={'DOCUMENTOS'} titleLineColor={globalSchemaSpa.secondary_color}>
            <Documents
              documents={documents}
              documentErrors={documentErrors}
              createFileTransaction={createFileTransaction}
              handleRemoveDocument={handleRemoveDocument}
              requiredAttachments={requiredFields.attachments} />
          </GenericCard>
          <GenericCard borderColor={globalSchemaSpa.secondary_color} backgroundColor={globalSchemaSpa.secondary_color} title={'DADOS DE REGISTRO'} titleLineColor={globalSchemaSpa.primary_color}>
            <RegistrationForm
              healthInstitutionField={requiredFields.extra_health_fields.includes('health_institution')}
              workSectionField={requiredFields.extra_health_fields.includes('work_section')}
              crmCoremField={requiredFields.extra_health_fields.includes('crm_corem')}
              accordingLawField={requiredFields.extra_health_fields.includes('according_law')}
              subGroupOptions={requiredFields.sub_groups}
              workSectionFieldOptions={requiredFields.work_section_options}
              globalSchemaSpa={globalSchemaSpa}
              user={user}
              saving={saving}
              setUser={setUser}
              userErrors={userErrors} />
          </GenericCard>
        </form>
      </div>
    </Grid>
  );
}

export default registrationHOC(Registration);
